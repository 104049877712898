
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#61DBE6',
      required: false,
    },
  },
})

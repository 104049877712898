
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      default: '#02C3CC',
      required: false,
    },
  },
})


import Vue from 'vue'
export default Vue.extend({
  props: {
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '#828282',
    },
  },
})

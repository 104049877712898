
import Vue from 'vue'
export default Vue.extend({
  props: {
    color: {
      type: String,
      required: false,
      default: '#3B4651',
    },
  },
})
